<template>
  <div class="tnc">
    <strong class="mb-4 text-base" style="display: block">MyKG Connection Terms and Conditions</strong>
    <div class="text-sm font-bold uppercase" style="margin-bottom: -0.5rem">
      Wi-Fi Access Terms and Conditions
    </div>
    <div class="preambule">
      <p>
        This agreement sets out the terms and conditions (&ldquo;S&amp;K&rdquo;) use of MyKG wireless internet
        access (&ldquo;Layanan&rdquo;) That We, which we, namely Kompas Gramedia in this case is represented
        by Corporate IT &amp; IS Kompas Gramedia (&ldquo;Kami&rdquo;) provide for you Kompas Gramedia
        employees or all parties who have access to the Service (&ldquo;Anda&rdquo;).
      </p>
      <p>
        Your access to the Service is entirely at our discretion. Access to the Service may be blocked,
        suspended, and/or terminated at any time for any reason including, but not limited to, violations of
        these T&amp;Cs, actions that We think may result in liability for Us, disruption of access to other
        users or other networks, and/or a violation of applicable laws or regulations.
      </p>
      <p>
        We reserve the right to monitor and collect information when you connect to the Service and the
        collected information can be used by us in accordance with our policies, including but not limited to
        sharing such information with law enforcement agencies, our affiliates, our partners and/or our
        vendors.
      </p>
      <p>
        We may revise these T&amp;Cs at any time. You declare that you accept and are bound by all terms along
        with changes to these T&amp;Cs each time you use the Service, and it is your responsibility to check
        it whenever it changes.
      </p>
      <p>
        We always reserve the right to stop the Service, change specifications or change the way you use the
        Service, including changing access codes, username, password or other security information required by
        you to access the Service.
      </p>
      <p>
        <strong>
          By using the Service, you agree to comply with these T&amp;Cs, including its amendments as may be
          amended by us from time to time at our discretion.
        </strong>
      </p>
    </div>
    <div class="article">
      <ol>
        <li>
          <span class="uppercase">Disclaimer</span>
          <div class="content">
            <p class="mt-1">You Acknowledge:</p>
            <ol>
              <li>That the Service may not be uninterrupted or error-free.</li>
              <li>
                That your device may be exposed to viruses or other harmful applications through the Service.
              </li>
              <li>
                That We provide the Service based on a limited warranty and based on the agreement you have
                given to these T&Cs.
              </li>
              <li>
                That We are not responsible for any hardware, software and/or account you need to be able to
                use the Service. We do not guarantee that the Service will be fully functional on the device
                you are using.
              </li>
              <li>
                That the CITIS KG can at any point block access to Internet Services that they deem violate
                the acceptable terms of use outlined in 2.1.
              </li>
            </ol>
          </div>
        </li>

        <li>
          <span class="uppercase">Acceptable Use of Service</span>
          <div class="content">
            <ol>
              <li>
                <p class="mt-1">
                  You must not use the Service to access Internet Services, or send or receive e-mails, which:
                </p>
                <ul>
                  <li>Are defamatory, threatening, intimidating or which could be classed as harassment,</li>
                  <li>Contain obscene, profane, or abusive language or material,</li>
                  <li>
                    Contain pornographic material (that is text, pictures, films, video clips of a sexually
                    explicit or arousing nature),
                  </li>
                  <li>
                    Contain offensive or derogatory images regarding sex, race, religion, color, origin, age,
                    physical or mental disability, medical condition or sexual orientation,
                  </li>
                  <li>
                    Contains material that violates the rights of us, our affiliates or third parties
                    (including intellectual property rights),
                  </li>
                  <li>In our reasonable opinion can influence the way we do our work,</li>
                  <li>
                    Contains message headers that are falsified or misinterpreted, in whole or in part, to
                    mask the real sender of the message,
                  </li>
                  <li>An activity that interferes with the privacy of others; or</li>
                  <li>Declared unlawful or inappropriate according to normal practice.</li>
                </ul>
              </li>
              <li>
                You agree that music, videos, images, text and other content on the internet are copyrighted
                works and you may not download, modify, email, or otherwise use such content unless you
                believe that the owner of the work has authorized your use ( public domains).
              </li>
              <li>
                You may not use the Service to access other parties' accounts, equipment, networks, and/or
                attempt to penetrate other system security measures illegally or without the computer's
                authorization of an authorized party. This includes any activity that may be used as a prelude
                to a system penetration attempt, including, but not limited to port scanning, stealth
                scanning, or other information gathering activities.
              </li>
              <li>
                You may not use the Service to spread internet viruses, Trojan Horses, or other malware.
              </li>
              <li>
                The Service is intended for Our employees, vendors who work for Us, and those who have access
                to the Service.
              </li>
              <li>
                We may stop or suspend the Service temporarily if, in our judgment, you violate the terms of
                the T&Cs including but not limited to clauses 2.1 to 2.5 above.
              </li>
              <li>
                We advise you not to use the Service to send or receive confidential information or data. If
                you still choose to do so, you are responsible for any risks that may occur.
              </li>
              <li>
                You may not use the Service in a way that may cause damage, paralysis, overload, or damage the
                connection of the Service or prevent the use of the Service by other users.
              </li>
            </ol>
          </div>
        </li>

        <li>
          <span class="uppercase">Criminal Activity</span>
          <div class="content">
            <ol>
              <li>
                You must not use the Service to engage in any activity which constitutes or can constitute a
                criminal offence, either in the Indonesia or in any country throughout the world.
              </li>
              <li>
                You acknowledge and agree that We may be required to provide assistance and information to law
                enforcement, government agencies and other authorities regarding the use of the Service.
              </li>
              <li>
                You agree and acknowledge that we will monitor your activity while you use this service and
                keep a log of the Internet Protocol (“IP”) addresses of any devices which access the Service,
                the times when they have accessed the Service and the activity associated with that IP
                address.
              </li>
              <li>
                You further agree we are entitled to co-operate with law enforcement authorities and
                rights-holders in the investigation of any suspected or alleged illegal activity by you which
                may include, but is not limited to, disclosure of such information as we have (whether
                pursuant to clause 3.3 or otherwise), and are entitled to provide by law, to law enforcement
                authorities or rights-holders.
              </li>
            </ol>
          </div>
        </li>

        <li>
          <span class="uppercase">Other Terms</span>
          <div class="content">
            <ol>
              <li>
                Under no circumstances will We, our respective suppliers or licensors, officers, directors,
                employees, agents and affiliates be liable for any consequential, indirect, special, punitive
                or incidental damages, whether foreseeable or not, based on Claims of the Guest or his/her
                designee (including, but not limited to, unauthorized access to, damage to or theft of your
                systems or data, claims for loss of goodwill, claims for loss of data, use of or reliance on
                services, other service outages), employment or downgrading value of other assets, or damage
                caused to equipment or programs from viruses or other malicious applications), arising from
                the breach or failure of any express or implied warranty, breach of contract,
                misrepresentation, negligence, strict liability in damages or otherwise.
              </li>
              <li>
                You agree to indemnify and hold harmless us and our suppliers or licensors, officers,
                directors, employees, agents, and affiliates from all claims, liabilities, losses, damages,
                costs, or expenses (without exception attorney fees if any) arising from or in connection with
                your use of the Service, any material you download or upload through the Service, any action
                you take in connection with your use of the Service, any violation of third party rights or
                violation of laws or regulations, and/or any violation of these T&Cs. This section shall not
                be construed to limit or exclude any claim or other remedy which may be asserted by Us under
                these T&Cs or by applicable law.
              </li>
              <li>
                This should not be construed as a provision to create a partnership, joint venture, agency
                relationship or franchise between the parties. Any waiver, amendment, or other amendment of
                these T&Cs will not take effect unless agreed in writing by Us. If any provision of these T&Cs
                is deemed unenforceable, in whole or in part, such provision will not affect the validity of
                the other provisions of these T&Cs.
              </li>
              <li>
                These T&Cs are subject to applicable law, so nothing in these T&Cs will override or prevent Us
                from complying with requests or law enforcement requirements relating to Your use of the
                Service, as well as any information We provide or collect in connection with such requests.
                These T&Cs are a complete and comprehensive statement of all terms, conditions and
                representations of the agreement between You and Us with respect to its subject matter and
                supersede all prior writings or understandings.
              </li>
            </ol>
          </div>
        </li>
      </ol>
    </div>

    <slot />
  </div>
</template>

<style scoped lang="scss">
.tnc {
  $fontSize: 0.875rem;

  font-size: $fontSize;
  line-height: 1.5;
  border-radius: 0.875rem !important;

  .preambule {
    margin-bottom: 1.5rem;
    p {
      margin: 0.75rem 0;
    }
  }

  .article {
    > ol {
      margin-left: 1.25rem;
      > li {
        margin: 1rem 0;
      }
      li ol,
      li ul {
        margin-left: 1rem;
        li {
          margin: 0.25rem 0;
        }
      }
      div.content,
      div.content * {
        font-size: $fontSize;
        font-weight: 400;
      }
      li,
      span.uppercase {
        font-size: 1rem;
        font-weight: 700;
      }
    }
  }

  :deep(.v-card-item:has(.v-card-title)) {
    top: 0;
    position: sticky;
    background: #fff;
    border-bottom: 1px solid #adb5bd;
  }
}
</style>
